<template>
  <div class="home">
    <ExamQuiz :idClassroom="$route.params.idClassroom"/>
  </div>
</template>

<script>
// @ is an alias to /src
import ExamQuiz from '@/components/exam/examQuiz/ExamQuizStart'

export default {
  name: 'ExamQuizView',
  components: {
    ExamQuiz
  }
}
</script>